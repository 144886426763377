<template>
  <div class="ud-body">
    <a-card :bordered="true">
      <a-form ref="form" :rules="rules" :label-col="labelCol" :model="form" :wrapper-col="wrapperCol" class="form">
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="手机号" name="phoneNumber">
              <a-input placeholder="请输入手机号" v-model:value="form.phoneNumber" allow-clear></a-input> </a-form-item></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="姓名" name="name">
              <a-input placeholder="请输入姓名" v-model:value="form.realName" allow-clear></a-input> </a-form-item></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="电池编号" name="batteryCode">
              <a-input placeholder="请输入电池编号" v-model:value="form.batteryCode" allow-clear></a-input> </a-form-item></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="订单编号" name="orderNumber">
              <a-input placeholder="请输入订单编号" v-model:value="form.orderNumber" allow-clear></a-input> </a-form-item></a-col>
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属运营商" name="operatorId">
              <a-select v-model:value="form.operatorId" placeholder="请选择所属运营商" @change="handleChangeOperator" allow-clear>
                <a-select-option v-for="item in operatorList" :key="item.operatorId" :value="item.operatorId">
                  {{ item.operatorName }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属租赁点" name="point">
              <a-select v-model:value="form.pointId" placeholder="请选择所属租赁点" allow-clear>
                <a-select-option v-for="item in pointList" :key="item.pointId" :value="item.pointId">
                  {{ item.pointName }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="客户状态" name="enable">
              <a-select v-model:value="form.enable" placeholder="请选择客户状态" allow-clear>
                <a-select-option v-for="item in customerStateList" :key="item.dictDataName" :value="item.dictDataCode">
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="订单状态" name="orderState">
              <a-select v-model:value="form.orderState" placeholder="请选择订单状态" allow-clear>
                <a-select-option v-for="item in orderStateList" :key="item.dictDataName" :value="item.dictDataCode">
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col>
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="开始时间" name="startTime">
              <a-range-picker v-model:value="startDateRange" :show-time="true" value-format="YYYY-MM-DD HH:mm:ss"
                class="ud-fluid" >
                <template #suffixIcon>
                  <calendar-outlined />
                </template>
              </a-range-picker> </a-form-item></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="到期时间" name="endTime">
              <a-range-picker v-model:value="endDateRange" :show-time="true" value-format="YYYY-MM-DD HH:mm:ss"
                class="ud-fluid">
                <template #suffixIcon>
                  <calendar-outlined />
                </template>
              </a-range-picker> </a-form-item></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="押金来源" name="depositSrc">
              <a-select v-model:value="form.depositSrc" placeholder="请选择押金来源" allow-clear>
                <a-select-option v-for="item in payTypeList" :key="item.dictDataName" :value="item.dictDataCode">
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="套餐" name="de">
              <a-select v-model:value="form.priceCode" placeholder="请选择套餐" allow-clear>
                <a-select-option v-for="item in priceList" :key="item.priceCode" :value="item.priceCode">
                  {{ item.priceName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row type="flex" justify="end">
          <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"><i class="iconfont icon-zaitu"></i>查询</a-button>
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button>
            <span v-if="!expand" @click="expandForm" style="color: #1890ff; cursor: pointer">
              展开<i class="iconfont icon-xiangxiajiantou"></i>
            </span>
            <span v-else @click="expandForm" style="color: #1890ff; cursor: pointer">
              收起<i class="iconfont icon-xiangshang_jiantou"></i>
            </span>
          </a-space>
        </a-row>
      </a-form>
    </a-card>
    <a-card :bordered="true" class="table">
      <div>
        <ud-pro-table ref="table" :rowKey="(record) => record.orderId" :columns="columns" :datasource="datasource"
          @change="handleChange" :scroll="{ x: 'max-content' }">
          <template #toolbar>
            <a-space style="margin: 0 20px">
              <a-button type="primary" @click="visibleCreateOrder = true">
                <template #icon>
                  <plus-outlined />
                </template>
                <span>新建</span>
              </a-button>
            </a-space>
          </template>
          <template #orderNumber="{ record }">
            <div style="color: #409eff; text-decoration: underline">
              {{ record.orderNumber }}
            </div>
          </template>
          <template #batteryCode="{ record }">
            <div style="color: #409eff; text-decoration: underline">
              {{ record.batteryCode }}
            </div>
          </template>
          <!-- <template #pointName="{ record }">
            <div style="color: #409eff; text-decoration: underline">
              {{ record.pointName }}
            </div>
          </template> -->
          <template #action="{ record }">
            <a-space>
              <a @click="openDetail(record)">详情</a>
              <a @click="openEdit(record)">修改</a>
              <a-divider type="vertical" />
              <a-popconfirm title="确定要删除此项目吗?" @confirm="remove(record)">
                <a class="ud-text-danger">删除</a>
              </a-popconfirm>
              <a-divider type="vertical" />
              <a-dropdown>
                <a class="ant-dropdown-link" @click.prevent>
                  更多
                  <DownOutlined />
                </a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item key="batteryCode" @click="alterBatteryCode(record)">更改电池编号</a-menu-item>
                    <a-menu-item key="refund" @click="refund(record)">退款</a-menu-item>
                    <a-menu-item key="endTime" @click="alertTime(record)">变更到期时间</a-menu-item>
                    <a-menu-item key="order" @click="ransferOfOrder(record)">订单转让</a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </a-space>
          </template>
          <template #enable="{ record }">
            {{ record.enable === 0 ? '启用' : '禁用' }}
          </template>
          <template #orderState="{ record }">
            {{
              record.orderState === 'cancel'
              ? '订单取消'
              : record.orderState === 'end'
                ? '完结'
                : record.orderState === 'frozen'
                  ? '冻结'
                  : record.orderState === 'unpaid'
                    ? '待结算'
                      : record.orderState === 'staging'
                      ? '暂存'
                        : record.orderState === 'unuse'
                          ? '未领取'
                          : '已领取'
            }}
          </template>
          <template #payType="{ record }">
            {{
              record.payType === 'balance'
              ? '余额支付'
              : record.payType === 'system'
                ? '系统充值'
                : '微信支付'
            }}
          </template>
          <template #depositSrc="{ record }">
            {{
              record.depositSrc === 'balance'
              ? '余额支付'
              : record.depositSrc === 'weixin'
                ? '微信支付'
                : '其它'
            }}
          </template>
          <template #customTitle>
            <div>
              所属运营商
              <i class="iconfont icon-bukejian" style="cursor: pointer" v-if="!isShowOperator"
                @click="isShowOperator = true"></i>
              <i class="iconfont icon-kejian" style="cursor: pointer" v-else @click="isShowOperator = false"></i>
            </div>
          </template>
          <template #operatorCustom="{ record }">
            <div v-if="!isShowOperator && record.operatorName">
              {{
                record.operatorName.substring(0, 1) +
                new Array(record.operatorName.length).join('*')
              }}
            </div>
            <div v-else>
              {{ record.operatorName }}
            </div>
          </template>
        </ud-pro-table>
      </div>
      <a-modal v-model:visible="visibleBatteryCode" title="更换电池编号" @ok="handleBatteryCodeOk" centered>
        <a-form ref="batteryForm" :model="batteryForm" :rules="batteryFormRules" :labelCol="labelCol"
          :wrapperCol="wrapperCol">
          <a-form-item label="更换电池编号">
            <a-input v-model:value.trim="batteryForm.batteryCode" placeholder="请输入更换电池编号">
            </a-input>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal v-model:visible="visibleRefund" title="提示" @ok="handleRefundOk" @cancel="handleReundCancle" centered>
        <div>
          <ExclamationCircleOutlined style="color: orange; margin-right: 10px" />
          <span>确定要申请退款嘛?</span>
        </div>
      </a-modal>
      <a-modal v-model:visible="visibleEndTime" title="请选择到期时间" @ok="handleEndTimeOk" centered>
        <a-form :labelCol="labelCol" :wrapperCol="wrapperCol" :model="actionForm">
          <a-form-item label="到期时间" name="endTime">
            <a-date-picker :show-time="true" v-model:value="actionForm.endTime" value-format="YYYY-MM-DD HH:mm:ss"
              style="width: 100%" />
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal v-model:visible="visibleOrder" title="订单转让" @ok="handleOrderOk" centered>
        <a-form :model="ransferOfOrderForm" :labelCol="{ md: { span: 6 }, sm: { span: 24 } }">
          <a-form-item label="受让人手机号" name="phone">
            <a-input v-model:value.trim="ransferOfOrderForm.phone" />
          </a-form-item>
          <!-- <a-form-item label="受让人姓名" name="realName">
            <a-input v-model:value="ransferOfOrderForm.realName" disabled />
          </a-form-item>
          <a-form-item label="受让人身份证号" name="idCard">
            <a-input v-model:value="ransferOfOrderForm.idCard" disabled />
          </a-form-item> -->
        </a-form>
      </a-modal>
      <a-modal v-model:visible="visibleLog" title="操作日志" @ok="handleLogOk" centered :width="1000" :footer="null">
        <a-table row-key="actionTime" :data-source="logData" :columns="logColumns" bordered :scroll="{ y: 500, x: 1300 }"
          :pagination="pagination">
          <template #actionContent="{ record }">
            <div>{{ record.actionContent.actionName }}</div>
            <div>{{ record.actionContent.after }}</div>
            <div>{{ record.actionContent.before }}</div>
          </template>
          <template #orderState="{ record }">
            {{
              record.orderState === 'cancel'
              ? '订单取消'
              : record.orderState === 'end'
                ? '完结'
                : record.orderState === 'frozen'
                  ? '冻结'
                  : record.orderState === 'unpaid'
                    ? '待结算'
                    : record.orderState === 'staging'
                      ? '暂存'
                        : record.orderState === 'unuse'
                          ? '未领取'
                          : '已领取'
            }}
          </template>
        </a-table>
      </a-modal>
      <a-modal v-model:visible="visibleCreateOrder" title="新建换电订单" @ok="handleCreateOrderOk" centered :width="680">
        <a-form ref="createOrderForm" :model="createOrderForm" :rules="createOrderRules"
          :labelCol="{ md: { span: 6 }, sm: { span: 24 } }">
          <a-form-item label="所属运营商" name="operatorId">
            <a-select v-model:value="createOrderForm.operatorId" placeholder="请选择所属运营商" @change="changeOperator">
              <a-select-option v-for="item in operatorList" :key="item.operatorId" :value="item.operatorId">
                {{ item.operatorName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="租赁点" name="pointId">
            <a-select v-model:value="createOrderForm.pointId" placeholder="请选择租赁点">
              <a-select-option v-for="item in pointList" :key="item.pointId" :value="item.pointId">
                {{ item.pointName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="套餐名" name="priceId">
            <a-select v-model:value="createOrderForm.priceId" placeholder="请选择计费模板编号">
              <a-select-option v-for="item in priceList" :key="item.priceId" :value="item.priceId">
                {{ item.priceName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="手机号" name="phone">
            <a-input v-model:value.trim="createOrderForm.phone" placeholder="请输入手机号">
            </a-input>
          </a-form-item>
        </a-form>
      </a-modal>
      <operation-detail v-model:visible="showDetail" :data="current || {}"></operation-detail>
      <operation-edit v-model:visible="showEdit" :data="current" @done="reload" />
    </a-card>
  </div>
</template>

<script>
import operationDetail from './operation-detail.vue'
import operationEdit from './operation-edit.vue'
import * as priceApi from '@/api/rnt/price'
import {
  DownOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  CalendarOutlined
} from '@ant-design/icons-vue'

import * as operatorApi from '@/api/rnt/operator'
import * as pointApi from '@/api/rnt/point.js'
import * as orderApi from '@/api/rnt/order.js'
export default {
  components: {
    DownOutlined,
    ExclamationCircleOutlined,
    CalendarOutlined,
    PlusOutlined,
    operationDetail,
    operationEdit
  },
  mounted() {
    this.queryPoint()
    this.queryOperater()
    this.queryPayType()
    this.queryCustomer()
    this.queryOrder()
    this.queryPrice()
  },
  watch: {
    startDateRange() {
      if (this.startDateRange && this.startDateRange.length === 2) {
        this.form.startTimeStart = this.startDateRange[0]
        this.form.startTimeEnd = this.startDateRange[1]
      } else {
        this.form.startTimeStart = null
        this.form.startTimeEnd = null
      }
    },
    endDateRange() {
      if (this.endDateRange && this.endDateRange.length === 2) {
        this.form.endTimeStart = this.endDateRange[0]
        this.form.endTimeEnd = this.endDateRange[1]
      } else {
        this.form.endTimeStart = null
        this.form.endTimeEnd = null
      }
    }
  },
  data() {
    return {
      priceList: [],
      startDateRange: [],
      endDateRange: [],
      operatorList: [],
      pointList: [],
      customerStateList: [],
      orderStateList: [],
      payTypeList: [],
      visibleLog: false,
      visibleOrder: false,
      visibleEndTime: false,
      visibleRefund: false,
      visibleBatteryCode: false,
      visibleCreateOrder: false,
      batteryForm: {},
      actionForm: {},
      ransferOfOrderForm: {},
      createOrderForm: {},
      labelCol: { md: { span: 6 }, sm: { span: 24 } },
      wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
      showDetail: false,
      showEdit: false,
      current: null,
      form: {},
      rules: {},
      createOrderRules: {
        operatorId: [
          {
            required: true,
            message: '请选择运营商',
            trigger: 'blur',
            type: 'number'
          }
        ],
        pointId: [
          {
            required: true,
            message: '请选择租赁点',
            trigger: 'blur',
            type: 'number'
          }
        ],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
            type: 'string'
          }
        ],
        priceId: [
          {
            required: true,
            message: '请选择计费模板编号',
            trigger: 'blur',
            type: 'number'
          }
        ]
      },
      expand: false,
      isShowOperator: false,
      columns: [
        {
          title: '序号',
          width: 48,
          align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '订单号',
          align: 'center',
          dataIndex: 'orderNumber'
        },
        {
          title: '订单状态',
          align: 'center',
          slots: {
            customRender: 'orderState'
          }
        },
        {
          title: '套餐名称',
          align: 'center',
          dataIndex: 'priceName'
        },
        {
          title: '押金',
          align: 'center',
          dataIndex: 'deposit'
        },
        {
          title: '押金来源',
          align: 'center',
          slots: {
            customRender: 'depositSrc'
          }
        },
        {
          title: '到期时间',
          align: 'center',
          dataIndex: 'endTime'
        },
        {
          title: '暂存时间',
          align: 'center',
          dataIndex: 'stagingTime'
        },
        {
          title: '电池编号',
          align: 'center',
          dataIndex: 'batteryCode'
        },
        {
          title: '手机号',
          align: 'center',
          dataIndex: 'username'
        },
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'realName'
        },
        {
          title: '客户状态',
          align: 'center',
          dataIndex: 'enable',
          slots: {
            customRender: 'enable'
          }
        },
        {
          align: 'center',
          slots: {
            title: 'customTitle',
            customRender: 'operatorCustom'
          }
        },
        {
          title: '所属租赁点',
          // width: 175,
          align: 'center',
          dataIndex: 'pointName'
        },
        {
          title: '创建时间',
          // width: 90,
          align: 'center',
          dataIndex: 'startTime'
        },
        {
          title: '操作',
          width: 200,
          dataIndex: 'action',
          align: 'center',
          slots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  methods: {
    // 条件表单运营商改变
    handleChangeOperator(value) {
      this.form.pointId = null
      pointApi
        .page({ operatorId: value })
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    changeOperator() {
      this.createOrderForm.pointId = null
      pointApi
        .page({ operatorId: this.createOrderForm.operatorId })
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 获取表格数据
    datasource(option, callback) {
      orderApi
        .page({
          ...this.form,
          page: option.page,
          limit: option.limit,
          sort: 'createTime',
          order: 'desc'
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 查询计费模板列表 */
    queryPrice() {
      priceApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.priceList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询客户状态列表 */
    queryCustomer() {
      orderApi
        .dictData({ dictCode: 'customerState' })
        .then((res) => {
          if (res.code === 0) {
            this.customerStateList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      orderApi
        .deleteById(row.orderId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 查询订单状态列表 */
    queryOrder() {
      orderApi
        .dictData({ dictCode: 'orderState' })
        .then((res) => {
          if (res.code === 0) {
            this.orderStateList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询支付方式列表 */
    queryPayType() {
      orderApi
        .dictData({ dictCode: 'PayType' })
        .then((res) => {
          if (res.code === 0) {
            this.payTypeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询所属租赁点 */
    queryPoint() {
      pointApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询运营商 */
    queryOperater() {
      operatorApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查看操作日志
    checkLog(record) {
      this.visibleLog = true
      this.actionForm.orderCode = record.orderCode
    },
    handleCreateOrderOk() {
      this.$refs.createOrderForm
        .validate()
        .then(() => {
          orderApi
            .createOrder(
              Object.assign({}, this.createOrderForm, { payType: 'balance' })
            )
            .then((res) => {
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
                this.createOrderForm = {}
                this.visibleCreateOrder = false
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.$message.error(e.message)
            })
        })
        .catch(() => { })
    },
    handleOrderOk() {
      orderApi
        .changeUser(this.ransferOfOrderForm)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
            this.visibleOrder = false
            this.ransferOfOrderForm = {}
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    ransferOfOrder(row) {
      this.ransferOfOrderForm.orderNumber = row.orderNumber
      this.visibleOrder = true
    },
    alertTime(row) {
      this.actionForm.orderNumber = row.orderNumber
      this.actionForm.endTime = row.endTime
      this.visibleEndTime = true
    },
    handleEndTimeOk() {
      orderApi
        .changeEndTime(this.actionForm)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
            this.visibleEndTime = false
            this.actionForm = {}
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    handleReundCancle() {
      this.$message.info('已取消操作')
    },
    handleRefundOk() {
      this.$message.success(
        '订单编号为' + this.actionForm.orderCode + '申请退款成功'
      )
      this.visibleRefund = false
      this.actionForm = {}
    },
    refund(row) {
      this.actionForm.orderCode = row.orderCode
      this.visibleRefund = true
    },
    alterBatteryCode(row) {
      this.batteryForm.orderNumber = row.orderNumber
      this.visibleBatteryCode = true
    },
    handleBatteryCodeOk() {
      orderApi
        .changeBatteryCode(this.batteryForm)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
            this.visibleBatteryCode = false
            this.batteryForm = {}
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    openEdit(row) {
      this.current = row
      this.showEdit = true
    },
    /* 刷新表格 */
    reload() {
      // this.form.pointId = 2
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.startDateRange = []
      this.endDateRange = []
      this.form = {}
      this.queryPoint()
      this.$nextTick(() => {
        this.reload()
      })
    },
    // 表格行自定义事件
    rowClick: (record, index) => {
      return {
        on: {
          click: () => {
            // this.rows.index = index //保存选中行的index
            console.log('当前选中的行' + index, '当前选中行数据' + record)
          }
        }
      }
    },
    // 新增计费模板确定按钮回调事件
    handleBillingOk() {
      this.$refs.billingForm.validate().then((res) => {
        console.log(res)
        this.$message.success('添加计费模板成功！')
        this.visible = false
      })
    },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    // 表格改变
    handleChange() { }
  }
}
</script>
<style lang='less' scoped>
.form {
  .btn-wrap {
    padding-right: 20px;

    .iconfont {
      margin-right: 5px;
    }
  }
}

.table {
  margin: 10px 0;
}
</style>

<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="1100"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 10 }, xs: { span: 8 } }"
      :wrapper-col="{ sm: { span: 16 }, xs: { span: 18 } }"
    >
      <a-row :gutter="24">
        <a-col :sm="8" :xs="24">
          <a-form-item label="订单号:">
            <div class="ud-text-secondary">
              {{ data.orderNumber }}
            </div>
          </a-form-item>
          <a-form-item label="订单状态:">
            <div class="ud-text-secondary">
              {{ data.orderState === 'cancel'
                ? '订单取消'
                :  data.orderState === 'end'
                ? '完结'
                :  data.orderState === 'frozen'
                ? '冻结'
                :  data.orderState === 'unpaid'
                ? '待结算'
                :  data.orderState === 'staging'
                ? '暂存'
                :  data.orderState === 'unuse'
                ? '未领取'
                : '已领取' }}
            </div>
          </a-form-item>
          <a-form-item label="暂存时间:" v-if="data.orderState === 'staging'">
            <div class="ud-text-secondary">
              {{ data.stagingTime }}
            </div>
          </a-form-item>
          <a-form-item label="到期时间:">
            <div class="ud-text-secondary">
              {{ data.endTime }}
            </div>
          </a-form-item>
          <a-form-item label="电池编号:">
            <div class="ud-text-secondary">
              {{ data.batteryCode }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="8" :xs="24">
          <a-form-item label="手机号:">
            <div class="ud-text-secondary">{{ data.username }}</div>
          </a-form-item>
          <a-form-item label="姓名:">
            <div class="ud-text-secondary">{{ data.realName }}</div>
          </a-form-item>
          <a-form-item label="客户状态:">
            <div class="ud-text-secondary">{{ data.enable===0?'启用':'禁用' }}</div>
          </a-form-item>
        </a-col>
        <a-col :sm="8" :xs="24">
          <a-form-item label="所属运营商:">
            <div class="ud-text-secondary">
              {{ data.operatorName }}
            </div>
          </a-form-item>
          <a-form-item label="所属租赁点:">
            <div class="ud-text-secondary">
              {{ data.pointName }}
            </div>
          </a-form-item>
          <a-form-item label="创建时间:">
            <div class="ud-text-secondary">
              {{ data.createTime }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'brandDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  methods: {
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
</style>

<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改换电订单' : '新建换电订单'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="customerForm"
      :rules="customerRules"
      :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="订单号" name="orderCode">
            <a-input
              placeholder="请输入订单号"
              v-model:value="customerForm.orderNumber"
              allow-clear
              diab
            ></a-input>
          </a-form-item>
          <a-form-item label="订单状态" name="orderState">
            <a-select
              v-model:value="customerForm.orderState"
              placeholder="请选择订单状态"
            >
              <a-select-option
                v-for="item in orderStateList"
                :key="item.dictDataName"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="手机号" name="username">
            <a-input
              placeholder="请输入手机号"
              v-model:value="customerForm.username"
              disabled
              allow-clear
            ></a-input>
          </a-form-item>
          <a-form-item label="姓名" name="realName">
            <a-input
              placeholder="请输入姓名"
              v-model:value="customerForm.realName"
              disabled
              allow-clear
            ></a-input>
          </a-form-item>
          <!-- <a-form-item label="客户状态" name="customerState">
            <a-select
              v-model:value="customerForm.enable"
              placeholder="请选择客户状态"
            >
              <a-select-option
                v-for="item in customerStateList"
                :key="item.dictDataName"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item> -->
          <a-form-item label="电池编号" name="batteryCode">
            <a-input
              placeholder="请输入电池编号"
              v-model:value="customerForm.batteryCode"
              allow-clear
            ></a-input>
          </a-form-item>
          <a-form-item label="套餐名" name="priceName">
            <a-select
              v-model:value="customerForm.priceName"
              placeholder="请选择计费模板编号"
              disabled
            >
              <a-select-option
                v-for="item in priceList"
                :key="item.priceId"
                :value="item.priceCode"
              >
                {{ item.priceName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="所属运营商" name="operatorId">
            <a-select
              v-model:value="customerForm.operatorId"
              placeholder="请选择所属运营商"
              @change="changeOperator"
            >
              <a-select-option
                v-for="item in operatorList"
                :key="item.operatorId"
                :value="item.operatorId"
              >
                {{ item.operatorName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="所属租赁点" name="pointId">
            <a-select
              v-model:value="customerForm.pointId"
              placeholder="请选择租赁点"
            >
              <a-select-option
                v-for="item in pointList"
                :key="item.pointId"
                :value="item.pointId"
              >
                {{ item.pointName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="到期时间" name="endTime">
            <a-date-picker
              style="width: 100%"
              :show-time="true"
              value-format="YYYY-MM-DD HH:mm:ss"
              placeholder="请选择到期时间"
              v-model:value="customerForm.endTime"
            />
          </a-form-item>
          <a-form-item label="暂存时间" name="stagingTime">
            <a-date-picker
              style="width: 100%"
              :show-time="true"
              value-format="YYYY-MM-DD HH:mm:ss"
              placeholder="请选择暂存时间"
              v-model:value="customerForm.stagingTime"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
// import * as batteryModelApi from '@/api/rnt/batteryModel'
// import * as operatorApi from '@/api/rnt/operator'
import * as operatorApi from '@/api/rnt/operator'
import * as pointApi from '@/api/rnt/point.js'
import * as orderApi from '@/api/rnt/order.js'
import * as priceApi from '@/api/rnt/price'
export default {
  name: 'batteryModelEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  mounted() {
    this.queryPoint()
    this.queryOperater()
    this.queryCustomer()
    this.queryOrder()
    this.queryPrice()
  },
  data() {
    return {
      operatorList: [],
      pointList: [],
      customerStateList: [],
      orderStateList: [],
      priceList: [],
      // 表单数据
      customerForm: Object.assign({}, this.data),
      // 编辑弹窗表单验证规则
      customerRules: {
        phoneNumber: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
            type: 'string'
          }
        ],
        operator: [
          {
            required: true,
            message: '请选择所属运营商',
            trigger: 'blur',
            type: 'string'
          }
        ],
        realName: [
          {
            message: '请输入真实姓名',
            trigger: 'blur',
            type: 'string'
          }
        ],
        IDNumber: [
          {
            required: true,
            message: '请输入身份证号码',
            trigger: 'blur',
            type: 'string'
          }
        ],
        deposit: [
          {
            required: true,
            message: '押金不为空且最大6位数,保留小数点2位',
            trigger: 'blur',
            type: 'string'
          }
        ],
        balance: [
          {
            required: true,
            message: '请输入余额',
            trigger: 'blur',
            type: 'string'
          }
        ],
        giveBalance: [
          {
            required: true,
            message: '请输入赠送余额',
            trigger: 'blur',
            type: 'string'
          }
        ],
        point: [
          {
            required: true,
            message: '请选择所属租赁点',
            trigger: 'blur',
            type: 'string'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 项目列表
      modules: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.customerForm = this.data
        this.isUpdate = true
      } else {
        this.customerForm = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  methods: {
    changeOperator() {
      this.customerForm.pointId = null
      pointApi
        .page({ operatorId: this.customerForm.operatorId })
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询计费模板列表 */
    queryPrice() {
      priceApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.priceList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询所属租赁点 */
    queryPoint() {
      pointApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询运营商 */
    queryOperater() {
      operatorApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询客户状态列表 */
    queryCustomer() {
      orderApi
        .dictData({ dictCode: 'customerState' })
        .then((res) => {
          if (res.code === 0) {
            this.customerStateList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询订单状态列表 */
    queryOrder() {
      orderApi
        .dictData({ dictCode: 'orderState' })
        .then((res) => {
          if (res.code === 0) {
            this.orderStateList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          // this.form.createUserId = this.$store.state.user.user.userId
          orderApi
            .save(this.customerForm, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 查询运营商 */
    // queryOperatorList() {
    //   operatorApi
    //     .page()
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.operatorList = res.data
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>
<style scoped lang='less'>
.inputNumber {
  width: 100%;
}
</style>

import request from '@/utils/request'

// api地址
const api = {
  all: '/rnt/order',
  save: '/rnt/order',
  update: '/rnt/order/update',
  info: '/rnt/order/',
  delete: '/rnt/order/',
  deleteBatch: '/rnt/order/batch',
  page: '/rnt/order/page',
  changeBatteryCode: 'rnt/order/ChangeBatteryCode',
  changeEndTime: 'rnt/order/ChangeEndTime',
  changeUser: 'rnt/order/ChangeUser',
  createOrder: 'rnt/order/createOrder',
  overdue: 'rnt/order/overdue',
  dictdata: '/sys/dictdata/page'
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询逾期列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function overdue(params) {
  return new Promise((resolve, reject) => {
    request.get(api.overdue, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteBatch(data) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 更改电池编号
 * @param data 参数
 * @returns {Promise<>}
 */
export function changeBatteryCode(data) {
  return new Promise((resolve, reject) => {
    request.post(api.changeBatteryCode, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 订单转让
 * @param data 参数
 * @returns {Promise<>}
 */
export function changeUser(data) {
  return new Promise((resolve, reject) => {
    request.post(api.changeUser, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 变更到期时间
 * @param data 参数
 * @returns {Promise<>}
 */
export function changeEndTime(data) {
  return new Promise((resolve, reject) => {
    request.post(api.changeEndTime, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 创建换电订单
 * @param data 参数
 * @returns {Promise<>}
 */
export function createOrder(data) {
  return new Promise((resolve, reject) => {
    request.post(api.createOrder, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.update, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.save, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function dictData(params) {
  return new Promise((resolve, reject) => {
    request.get(api.dictdata, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
